.logo {
  position: relative;
  width: 100px;
  height: 64px;
  padding: 0 0 0 14px;
  overflow: hidden;
  transition: width 200ms;

  svg {
    max-width: 100%;
    max-height: 100%;
    transform: rotate(14deg);
    transition: transform 200ms;
  }

  &.collapsed {
    width: 60px;

    svg {
      transform: rotate(0);
    }
  }
}

.sider {
  position: fixed;
  left: 0;
  height: 100vh;
  background-color: #fff;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0 1px 4px rgba(0,21,41,0.08);
}

.siteLayout .header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 30px;
  background-color: #fff;

  height: 48px;
  z-index: 2;

  box-shadow: 0 1px 4px rgba(0,21,41,0.08);
}

:global {
  .ant-menu-inline {
    height: 100%;
  }

  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border: 0 none;
  }

  .ant-layout.ant-layout-has-sider > .ant-layout {
    overflow-x: initial;
  }
}
