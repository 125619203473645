.ql-editor {

  // 1 = 40pt, Bold, #19C2C6
  // 2 = 30pt, Bold, #3C3C3C
  // 3 = 24pt, Bold, #19C2C6

  h2 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #19C2C6;
  }

  h3 {
    font-size: 1.875rem;
    font-weight: bold;
    color: #3C3C3C;
  }

  h4 {
    font-size: 18px;
    font-weight: 700;
    color: rgba(0,0,0,.75);
  }

  p, li {
    font-family: 'PT serif', Arial;
    font-size: 1.25rem;
    line-height: 2rem;
    color: #555D5D;
  }

  p {
    margin: 1.3rem 0;
  }

  p a {
    color: #FF486C;
    text-decoration: none;
  }

  img {
    max-width: 100%;
  }

  ul, ol {
    margin-bottom: 36px;

    li {
      position: relative;
      list-style: none;
      margin-bottom: 0.875rem;

      &:before {
        left: -2rem;
      }
    }
  }

  ul > li:before {
    display: block;
    width: .75rem;
    height: .75rem;
    border-radius: 50%;
    background-color: #19C2C6;

    position: absolute;
    top: .75rem;
    content: '';
  }

  ol {
    counter-reset: list;
  }

  ol > li {
    counter-increment: list;
  }

  ol > li:before {
    color: #19C2C6;
    font-weight: 600;

    position: absolute;
    top: 1px;
    content: counter(list) '.';
  }

  .ql-video {
    width: 100%;
    height: 1000000px;
    max-height: 300px;

    @media (max-width: 576px) {
      max-height: 180px;
    }
	}
}
